<template>
   <div class="video-block section-padding">
      <div class="row">
         <div class="col-md-12">
            <div class="single-video-left">
               <div class="single-video">
                  <iframe width="100%" height="500" :src="url" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
               </div>
               <!-- <div class="single-video-title box mb-3">
                  <h2><a href="#"></a></h2>
                  <p class="mb-0"><i class="fas fa-calendar-alt"></i> </p>
               </div>
               <div class="single-video-info-content box mb-3">
              
               </div> -->
            </div>
         </div>
         <div class="col-md-12" style="margin-top: 4vh;">
            <div class="single-video-right">
               <div class="row">
                  <div class="col-md-12">
                     <div class="main-title">
                        <h6>مقترحات</h6>
                     </div>
                  </div>
                  <div class="col-6 col-sm-4 col-lg-3 col-xl-2" v-for="(publish, index) in cat" :key="index">
                    <div class="video-card">
                        <div class="video-card-image">
                            <a class="play-icon" @click="getFeed(publish)"><i class="fas fa-play-circle"></i></a>
                            <a @click="getFeed(publish)"><img class="img-fluid" v-lazy="publish.img" alt></a>
                            <div class="time">{{ publish.file_duration }}</div>
                        </div>
                        <div class="video-card-body">
                            <div class="video-title">
                                <a @click="getFeed(publish)">{{ publish.title_ar }}</a>
                            </div>
                            <div class="video-page text-success">
                                {{ publish.name_ar }}  <a title data-placement="top" data-toggle="tooltip" href="#" data-original-title="Verified"><i class="fas fa-check-circle text-success"></i></a>
                            </div>
                            <div class="video-view">
                               &nbsp;<i class="fas fa-calendar-alt"></i> {{ publish.upload_date.substring(0,10) }}
                            </div>
                        </div>
                    </div>
                </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import { HTTP } from '@/Api/http-common';
import { ref } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next';
export default {
   name: 'AppContents',
   props:{
      id: String
   },
   data() {
       return { url: null };
    },
   mounted() {
    this.url='http://da7ktna.com/VideoPlayer/video.php?ID='+this.$route.params.id+'&MDN=249123901415';

   },
   async setup(props) {
         const router = useRouter();
         const route = useRoute();
         const cookie = useCookie()

        const video = ref([]);
        const cat = ref([]);
        try {
          await HTTP.get(`ContentById.php?CID=`+props.id).then((res) => {
            video.value = res.data.Content;
          });
        } catch (err) {
            console.log(err);
        }
        try {
          await HTTP.get(`GetContentByCategory.php?cat_id=`+cookie.getCookie("cat_id")+`&LIMIT=7&OFFSET=0`).then((res) => {
            cat.value = res.data.Content; 
          });
        } catch (err) {
          console.log(err);
        }
        const getFeed = (publish) => {
            cookie.setCookie('cat_id', publish.cat_id);
            router.push({ name: "Contents", params: { id: publish.id } });
        };
   
    return {
        video,
        cat,
        getFeed
    }
    },
}
</script>

<style>

</style>